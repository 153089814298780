import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Tag, Space, Modal, Input, Row, notification } from "antd";
import Label from "../../../../../models/Label";
import { EditOutlined } from "@ant-design/icons";
import ProcessingResult from "../../../../../models/ProcessingResult";
import { RequestsAPIHelper } from "../../../../../api-helpers/RequestsAPIHelper";

interface EditableLabelProps {
  requestIdx: number;
  result: ProcessingResult;
  label: Label;
  editable?: boolean;
}

function EditableLabel(props: EditableLabelProps) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [updatedLabel, setUpdatedLabel] = useState<Label>(props.label);
  const [newLabel, setNewLabel] = useState<string>(props.label.reading);
  const editable = props.editable === undefined ? true : props.editable;

  const color = useMemo(() => {
    if (updatedLabel.reading === "") {
      return "#a0d911";
    }
    return updatedLabel.valid ? "#389e0d" : "#d4380d";
  }, [updatedLabel]);

  useEffect(() => {
    setUpdatedLabel(props.label);
  }, [props.label]);

  useEffect(() => {
    if (showEditModal) {
      return;
    }
    setNewLabel(updatedLabel.reading);
  }, [updatedLabel, showEditModal]);

  const onEditClick = useCallback(() => {
    setShowEditModal(true);
  }, []);

  const onCancel = useCallback(() => {
    setShowEditModal(false);
    setNewLabel(updatedLabel.reading);
  }, [updatedLabel]);

  const onOk = useCallback(() => {
    RequestsAPIHelper.editLabelAsync(
      props.requestIdx,
      props.label.idx,
      newLabel
    ).then((res) => {
      if (res) {
        setUpdatedLabel(res);
        notification.success({
          message: "Label edited successfully",
          description: `Label ${res.manual_label} edited successfully.`,
        });
      }
    });
    setShowEditModal(false);
  }, [newLabel, props.label.idx, props.requestIdx]);

  const imageUrl =
    window._env_.REACT_APP_DRONE_INVENTORY_API_URL.slice(0, -1) +
    props.label.crop_path;

  return (
    <Space direction="horizontal">
      <Tag color={color}>
        {updatedLabel.reading !== ""
          ? updatedLabel.reading.toUpperCase()
          : "EMPTY"}
      </Tag>
      {editable && (
        <Button
          type="primary"
          size="small"
          icon={<EditOutlined />}
          onClick={onEditClick}
        />
      )}
      <Modal
        title="Edit Label"
        open={showEditModal}
        onCancel={onCancel}
        onOk={onOk}
      >
        <Row justify={"center"}>
          <Space direction="vertical" align="center" size={"large"}>
            <img src={imageUrl} alt="" />
            <Input
              placeholder="New label"
              value={newLabel.toUpperCase()}
              onChange={(e) => setNewLabel(e.target.value)}
            />
          </Space>
        </Row>
      </Modal>
    </Space>
  );
}

export default EditableLabel;
