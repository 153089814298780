import { useEffect } from "react";
import { Image, Table, Button, Col, Row, Divider } from "antd";
import ImageProcessingResult from "../../../../../models/ImageProcessingResult";
import LabelMatch from "../../../../../models/LabelMatch";
import { Link } from "react-router-dom";
import EditableLabel from "./EditableLabel";
import ProcessingResult from "../../../../../models/ProcessingResult";
import Label from "../../../../../models/Label";
const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;

interface ImagesReviewTabProps {
  image_processing_result: ImageProcessingResult;
  result: ProcessingResult;
  image_index: number;
  prev_disabled: boolean;
  next_disabled: boolean;
  requestIdx: number;
}

const buttonStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

function ImagesReviewTab(props: ImagesReviewTabProps) {
  const columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: LabelMatch) => {
        return (
          <EditableLabel label={item.position_label} result={props.result} requestIdx={props.requestIdx}/>
        );
      },
    },
    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: LabelMatch) => {
        if (item.pallet_label == null) {
          return "";
        }
        return (
          <EditableLabel label={item.pallet_label} result={props.result} requestIdx={props.requestIdx}/>
        );
      },
    },
  ];
  useEffect(() => {
    console.log(props);
  }, [props]);
  return (
    <>
      <Row gutter={[8, 8]} justify="center" align="middle">
        <Col xl={14} lg={24} style={{ height: "550px" }}>
          <Image
            src={
              API_BASE_URL.slice(0, -1) +
              props.image_processing_result.result_path
            }
            alt={"Unable to load image."}
            width={"95%"}
            style={{
              maxHeight: "500px",
            }}
          ></Image>
        </Col>
        <Col xl={10} lg={24}>
          <Row gutter={20}>
            <Col span={24}>
              <Divider> Matched Labels </Divider>
              <Table
                columns={columns}
                dataSource={props.image_processing_result.matched_labels}
                rowKey="id"
                pagination={false}
              />
            </Col>
            {props.image_processing_result.unmatched_positions.length > 0 && (
              <Col span={12}>
                <Divider> Unmatched Positions </Divider>
                <Table
                  columns={[
                    {
                      key: "position_label",
                      render: (item: Label) => {
                        return (
                          <EditableLabel label={item} result={props.result} requestIdx={props.requestIdx}/>
                        );
                      },
                    },
                  ]}
                  dataSource={props.image_processing_result.unmatched_positions}
                  rowKey="id"
                  pagination={false}
                  showHeader={false}
                />
              </Col>
            )}
            {props.image_processing_result.unmatched_pallets.length > 0 && (
              <Col span={12}>
                <Divider> Unmatched Pallets </Divider>
                <Table
                  columns={[
                    {
                      key: "pallet_label",
                      render: (item: Label) => {
                        return (
                          <EditableLabel
                            label={item}
                            result={props.result}
                            requestIdx={props.requestIdx}
                          />
                        );
                      },
                    },
                  ]}
                  dataSource={props.image_processing_result.unmatched_pallets}
                  rowKey="id"
                  pagination={false}
                  showHeader={false}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider />
      <div style={buttonStyle}>
        <Link
          to={
            "/requests/" +
            props.result.idx +
            "/?tab=4&imageId=" +
            (props.image_index - 1)
          }
        >
          <Button type="primary" disabled={props.prev_disabled}>
            Previous
          </Button>
        </Link>
        <Link
          to={
            "/requests/" +
            props.result.idx +
            "/?tab=4&imageId=" +
            (props.image_index + 1)
          }
        >
          <Button type="primary" disabled={props.next_disabled}>
            Next
          </Button>
        </Link>
      </div>
    </>
  );
}

export default ImagesReviewTab;
