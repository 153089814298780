import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  DescriptionsProps,
  Descriptions,
  Button,
  Space,
  Row,
  Col,
  Divider,
  notification,
  Spin,
} from "antd";
import { useState } from "react";
import { RequestsAPIHelper } from "../../../../../api-helpers/RequestsAPIHelper";
import { Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  Tooltip,
  Title,
} from "chart.js";
Chart.register([
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
]);

interface ReviewTabProps {
  requestIdx: number;
  status: string;
  images: number;
  matched_labels: number;
  unmatched_positions: number;
  unmatched_pallets: number;
}

function ReviewTab(props: ReviewTabProps) {
  const [approveDisabled, setApproveDisabled] = useState<boolean>(
    props.status === "approved"
  );
  const [rejectedDisabled, setRejectedDisabled] = useState<boolean>(
    props.status === "rejected"
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleApprove = () => {
    setLoading(true);
    RequestsAPIHelper.approveRequestAsync(props.requestIdx).then((res) => {
      if (res) {
        setApproveDisabled(true);
        setRejectedDisabled(false);
        notification.success({
          message: "Request approved successfully",
          description: `Request ${props.requestIdx} approved successfully.`,
        });
      } else {
        notification.error({
          message: "Request approved failed",
          description: `Request ${props.requestIdx} approved failed.`,
        });
      }
      setLoading(false);
    });
  };

  const handleReject = () => {
    setLoading(true);
    RequestsAPIHelper.rejectRequestAsync(props.requestIdx).then((res) => {
      if (res) {
        setApproveDisabled(false);
        setRejectedDisabled(true);
        notification.success({
          message: "Request rejected successfully",
          description: `Request ${props.requestIdx} rejected successfully.`,
        });
      } else {
        notification.error({
          message: "Request rejected failed",
          description: `Request ${props.requestIdx} rejected failed.`,
        });
      }
      setLoading(false);
    });
  };

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: "Images",
      children: props.images,
    },
    {
      key: "2",
      label: "Matched Labels",
      children: props.matched_labels,
    },
    {
      key: "3",
      label: "Unmatched Positions",
      children: props.unmatched_positions,
    },
    {
      key: "4",
      label: "Unmatched Pallets",
      children: props.unmatched_pallets,
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row gutter={16} align="middle" justify="center">
        <Col span={8}>
          <center>
            <Descriptions
              title="Results Brief"
              items={items}
              bordered
              column={1}
            />
          </center>
        </Col>
        <Col span={5} />
        <Col span={8}>
          <div>
            <Doughnut
              height={300}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Results Distribution",
                    font: {
                      size: 16,
                    },
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      font: {
                        size: 12,
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label + ": " + context.parsed;
                      },
                    },
                  },
                },
              }}
              data={{
                labels: [
                  "Matched Labels",
                  "Unmatched Positions",
                  "Unmatched Pallets",
                ],
                datasets: [
                  {
                    data: [
                      props.matched_labels,
                      props.unmatched_positions,
                      props.unmatched_pallets,
                    ],
                    backgroundColor: ["#5ce67c", "#36A2EB", "#FF6384"],
                  },
                ],
              }}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
      >
        <Space wrap>
          <Button
            type="primary"
            onClick={() => handleApprove()}
            shape="round"
            icon={<CheckCircleOutlined />}
            disabled={approveDisabled}
          >
            Approve
          </Button>
          <Button
            type="primary"
            shape="round"
            icon={<CloseCircleOutlined />}
            onClick={() => handleReject()}
            disabled={rejectedDisabled}
            danger
          >
            Reject
          </Button>
        </Space>
      </div>
    </Spin>
  );
}

export default ReviewTab;
