import { useCallback, useEffect, useMemo, useState } from "react";
import { WarehouseStateEntrySchema } from "../../../../models/PositionHistory";
import { PositionHistoryAPIHelper } from "../../../../api-helpers/PositionHistoryAPIHelper";
import { Button, Card, Space } from "antd";
import WarehouseStateTable from "./subcomponents/WarehouseStateTable";
import WarehouseStateControls from "./subcomponents/WarehouseStateControls";
import { DownloadOutlined } from "@ant-design/icons";

const WarehouseStateView = () => {
  const [warehouseState, setWarehouseState] = useState<
    WarehouseStateEntrySchema[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [palletFilter, setPalletFilter] = useState<string>("");
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [columnFilter, setColumnFilter] = useState<string>("");
  const [levelFilter, setLevelFilter] = useState<string>("");

  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
      corridor: string,
      column: string,
      level: string
    ) => {
      setPositionFilter(position);
      setPalletFilter(pallet);
      setCorridorFilter(corridor);
      setColumnFilter(column);
      setLevelFilter(level);
    },
    []
  );

  const updateContent = () => {
    setLoading(true);
    PositionHistoryAPIHelper.getWarehouseStateAsync().then((res) => {
      if (res === null) {
        return;
      }
      setWarehouseState(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    updateContent();
  }, []);

  const exportReport = useCallback(() => {
    PositionHistoryAPIHelper.getWarehouseStateCSVAsync().then((res) => {
      if (res === null) {
        return;
      }
      const blob = new Blob([res], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "warehouse_state.csv";
      a.click();
    });
  }, []);

  const filteredWarehouseState = useMemo(() => {
    console.log("filtering");
    console.log(positionFilter);
    let filtered = warehouseState;
    if (positionFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position
          .toLocaleUpperCase()
          .includes(positionFilter.toLocaleUpperCase())
      );
    }
    if (palletFilter !== "") {
      filtered = filtered.filter((entry) => {
        if (entry.latest_record.pallet === "") {
          return "empty"
            .toLocaleUpperCase()
            .includes(palletFilter.toLocaleUpperCase());
        }
        return entry.latest_record.pallet
          .toLocaleUpperCase()
          .includes(palletFilter.toLocaleUpperCase());
      });
    }
    if (corridorFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position[0]
          .toUpperCase()
          .includes(corridorFilter.toLocaleUpperCase())
      );
    }
    if (columnFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position
          .toLocaleUpperCase()
          .slice(2, 4)
          .includes(columnFilter.toLocaleUpperCase())
      );
    }
    if (levelFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position
          .toLocaleUpperCase()
          .slice(4, 6)
          .includes(levelFilter.toLocaleUpperCase())
      );
    }
    return filtered;
  }, [
    warehouseState,
    positionFilter,
    palletFilter,
    corridorFilter,
    columnFilter,
    levelFilter,
  ]);

  return (
    <>
      <Card
        title={
          <Space size={"large"}>
            Warehouse State
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              onClick={exportReport}
            >
              Download
            </Button>
          </Space>
        }
      >
        <WarehouseStateControls onApply={onApplyFilters} />
      </Card>
      <Card>
        <WarehouseStateTable state={filteredWarehouseState} loading={loading} />
      </Card>
    </>
  );
};

export default WarehouseStateView;
