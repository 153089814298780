import { Button, Card, Modal, Space, Spin, notification } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import NewProcessingRequestForm from "./NewProcessingRequestForm";
import RequestsHistoryTable from "./subcomponents/RequestsHistoryTable";
import { ProcessingRequestShow } from "../../../../models/ProcessingRequest";
import RetryProcessingRequestForm from "./RetryProcessingRequestForm";
import { RequestsAPIHelper } from "../../../../api-helpers/RequestsAPIHelper";

function RequestsHistory() {
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalNewOpen, setIsModalNewOpen] = useState<boolean>(false);
  const [isModalRetryOpen, setIsModalRetryOpen] = useState<boolean>(false);
  const [requests, setRequests] = useState<Array<ProcessingRequestShow>>([]);
  const [retryRequest, setRetryRequest] =
    useState<ProcessingRequestShow | null>(null);
  const onNew = () => {
    setIsModalNewOpen(true);
  };

  const updateContent = useCallback(() => {
    RequestsAPIHelper.getAllRequestsAsync("order_by=-id").then((requests) => {
      if (requests == null) {
        return;
      }
      setRequests(requests);
      setLoading(false);
    });
  }, []);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 5000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  const onUploadComplete = (success: boolean) => {
    setIsModalNewOpen(false);
    setIsModalRetryOpen(false);
    if (success) {
      notification.success({
        message: "Request created",
        description: `The request was created successfully.`,
      });
    } else {
      notification.error({
        message: "Error creating request",
        description: `There was an error creating the request.`,
      });
    }
    updateContent();
  };

  const onRetry = (request: ProcessingRequestShow) => {
    console.log(request);
    setRetryRequest(request);
    setIsModalRetryOpen(true);
  };

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Spin spinning={loading}>
          <Card
            title={
              <Space size={20}>
                Sweeps
                <Button
                  type="primary"
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  onClick={onNew}
                >
                  New
                </Button>
              </Space>
            }
          >
            <RequestsHistoryTable onRetry={onRetry} requests={requests} />
          </Card>
        </Spin>
        <Modal
          open={isModalNewOpen}
          footer={null}
          destroyOnClose
          onCancel={() => {
            setIsModalNewOpen(false);
          }}
        >
          <NewProcessingRequestForm onUploadComplete={onUploadComplete} />
        </Modal>
        <Modal
          open={isModalRetryOpen}
          footer={null}
          destroyOnClose
          onCancel={() => {
            setIsModalRetryOpen(false);
          }}
        >
          <RetryProcessingRequestForm
            retryRequest={retryRequest}
            onUploadComplete={onUploadComplete}
          />
        </Modal>
      </Space>
    </>
  );
}

export default RequestsHistory;
