import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import { useCallback, useEffect, useState } from "react";
import LoginPage from "./components/login/LoginPage";
import { LoginAPIHelper } from "./api-helpers/LoginAPIHelper";
import Dashboard from "./components/layout/contents/Dashboard";
import RequestsHistory from "./components/layout/contents/Requests/RequestsHistory";
import Details from "./components/layout/contents/Requests/Details";
import WarehouseStateView from "./components/layout/contents/Warehouse/WarehouseStateView";
import PositionHistoryView from "./components/layout/contents/PositionHistory/PositionHistoryView";

function App() {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const refreshAccessToken = useCallback(() => {
    LoginAPIHelper.validateAccessToken()
      .then((_) => {
        const token = sessionStorage.getItem("token");
        if (token === null || token !== accessToken) {
          setAccessToken(token);
        }
        setLoading(false);
      })
      .catch(() => {
        setAccessToken(null);
        setLoading(false);
      });
  }, [accessToken]);

  // ComponentDidMount equivalent
  useEffect(() => {
    refreshAccessToken();
    const interval = setInterval(() => {
      refreshAccessToken();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [refreshAccessToken]);

  if (loading) {
    return <></>;
  }

  if (!accessToken) {
    return <LoginPage onSuccess={setAccessToken} />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <AppLayout>
              <Routes>
                <Route path="*" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="requests">
                  <Route path="" element={<RequestsHistory />} />
                  <Route path=":requestId" element={<Details />} />
                </Route>
                <Route path="warehouse" element={<WarehouseStateView />} />
                <Route
                  path="positionhistory"
                  element={<PositionHistoryView />}
                />
              </Routes>
            </AppLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
