import axios, { AxiosResponse } from 'axios';
import { LoginAPIHelper } from './LoginAPIHelper';
import { CorridorOccupationSchema } from '../models/PositionHistory';

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;
const API_RESOURCE = 'stats/';

export class StatsAPIHelper {
  public static async getHistogram(query_string: string = '', as_file: boolean = false) {
    try {
      const response: AxiosResponse = await axios.get(API_BASE_URL + API_RESOURCE + 'histogram/?' + query_string, {
        headers: LoginAPIHelper.getAuthHeader(),
        responseType: as_file ? 'blob' : 'json'
      });

      if (as_file) {
        return response;
      }

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getHourlyCount(query_string: string = '') {
    try {
      const response: AxiosResponse = await axios.get(API_BASE_URL + API_RESOURCE + 'hourlycount/?' + query_string, {
        headers: LoginAPIHelper.getAuthHeader(),
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getComposition(query_string: string = '') {
    try {
      const response: AxiosResponse = await axios.get(API_BASE_URL + API_RESOURCE + 'composition/?' + query_string, {
        headers: LoginAPIHelper.getAuthHeader(),
      });

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getOccupationDistributionAsync(
  ): Promise<CorridorOccupationSchema[] | null> {
    try {
      const response: AxiosResponse<CorridorOccupationSchema[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + "occupationdistribution",
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return response.data.map((dto) => CorridorOccupationSchema.from(dto));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}