import { Card, Space, TabsProps, Tabs, Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MatchedLabelsTableTab from "./subcomponents/MatchedLabelsTableTab";
import ImagesReviewTab from "./subcomponents/ImagesReviewTab";
import { RequestsAPIHelper } from "../../../../api-helpers/RequestsAPIHelper";
import { ProcessingRequestShow } from "../../../../models/ProcessingRequest";
import UnmatchedLabelsTableTab from "./subcomponents/UnmatchedLabelsTableTab";
import ReviewTab from "./subcomponents/ReviewTab";
import { DownloadOutlined } from "@ant-design/icons";

const Details: React.FC = () => {
  const [request, setRequest] = useState<ProcessingRequestShow>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { requestId } = useParams();
  let tabId = searchParams.get("tab") || "1";
  let imageId = searchParams.get("imageId") || "0";

  const exportReport = useCallback(() => {
    console.log("1")
    if (request === undefined){
      return;
    }
    console.log("2")
    RequestsAPIHelper.getReviewCSVAsync(request.idx).then((res) => {
      if (res === null) {
        return;
      }
      const blob = new Blob([res], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "request_" + request.idx.toString() + "_review.csv";
      a.click();
    });
  }, [request]);

  console.log(tabId);
  const updateContent = useCallback(() => {
    if (requestId === undefined) {
      return;
    }
    RequestsAPIHelper.getSingleRequestAsync(requestId).then((request_) => {
      if (request_ == null || request_ == null) {
        return;
      }
      setRequest(request_);
    });
  }, [requestId]);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);
  if (
    request === null ||
    request === undefined ||
    request.processing_result === null
  ) {
    return <></>;
  }

  if (parseInt(imageId) < 0) {
    imageId = "0";
  }
  if (
    parseInt(imageId) >
    request.processing_result.image_processing_results.length
  ) {
    imageId = (
      request.processing_result.image_processing_results.length - 1
    ).toString();
  }

  

  const tabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Matched Labels",
      children: <MatchedLabelsTableTab request={request} />,
    },
    {
      key: "2",
      label: "Unmatched Positions",
      children: <UnmatchedLabelsTableTab request={request} type={"position"} />,
    },
    {
      key: "3",
      label: "Unmatched Pallets",
      children: <UnmatchedLabelsTableTab request={request} type={"pallet"} />,
    },
    {
      key: "4",
      label: "Images",
      children: (
        <ImagesReviewTab
          image_processing_result={
            request.processing_result.image_processing_results[
              parseInt(imageId ? imageId : "0")
            ]
          }
          next_disabled={
            parseInt(imageId ? imageId : "0") + 1 ===
            request.processing_result.image_processing_results.length
          }
          prev_disabled={parseInt(imageId ? imageId : "0") === 0}
          result={request.processing_result}
          image_index={parseInt(imageId ? imageId : "0")}
          requestIdx={request.idx}
        />
      ),
    },
    {
      key: "5",
      label: "Review",
      children: (
        <ReviewTab
          requestIdx={request.idx}
          status={request.status}
          images={request.processing_result.images.length}
          matched_labels={request.processing_result.image_processing_results.reduce(
            (total, imageResult) => total + imageResult.matched_labels.length,
            0
          )}
          unmatched_pallets={request.processing_result.image_processing_results.reduce(
            (total, imageResult) =>
              total + imageResult.unmatched_pallets.length,
            0
          )}
          unmatched_positions={request.processing_result.image_processing_results.reduce(
            (total, imageResult) =>
              total + imageResult.unmatched_positions.length,
            0
          )}
        />
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Space size={20}>Result Details</Space>
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                onClick={exportReport}
              >
                Export
              </Button>
            </div>
          }
        >
          <Tabs
            items={tabs}
            activeKey={tabId}
            onTabClick={(activeKey) => {
              setSearchParams({ tab: activeKey });
            }}
          />
        </Card>
      </Space>
    </>
  );
};

export default Details;
