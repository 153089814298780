import { Image, Table, Tag } from "antd";
import {
  PositionHistory,
  PositionHistoryRecord,
} from "../../../../../models/PositionHistory";

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;

interface PositionHistoryTableProps {
  positionHistory: PositionHistory;
  loading: boolean;
}

const PositionHistoryTable = (props: PositionHistoryTableProps) => {
  const columns = [
    {
      title: "",
      key: "image",
      render: (item: PositionHistoryRecord) => {
        const path = API_BASE_URL.slice(0, -1) + item.source_image.result_path;
        return (
          <Image src={path} alt={"Unable to load image."} height={"50px"} />
        );
      },
    },
    {
      title: "Timestamp",
      key: "timestamp",
      render: (item: PositionHistoryRecord) => {
        return <Tag color={"#389e0d"}>{item.timestamp}</Tag>;
      },
    },
    {
      title: "Pallet",
      key: "pallet",
      render: (item: PositionHistoryRecord) => {
        return (
          <Tag color={"#389e0d"}>
            {item.pallet === "" ? "EMPTY" : item.pallet.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.positionHistory.records}
      loading={props.loading}
    />
  );
};

export default PositionHistoryTable;
