import { Input, Col, Row, Space } from "antd";
import { useState, useCallback, useEffect } from "react";

interface UnmatchedLabelsControlsProps {
  type: "position" | "pallet";
  onApply: (position: string, pallet: string) => void;
}

const UnmatchedLabelsControls = (props: UnmatchedLabelsControlsProps) => {
  const [position, setPosition] = useState<string>("");
  const [pallet, setPallet] = useState<string>("");

  const onApply = useCallback(() => {
    props.onApply(position, pallet);
  }, [props, position, pallet]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  return (
    <Row gutter={20}>
      {props.type === "position" ? (
        <Col>
          <Space>
            Position
            <Input
              placeholder="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
          </Space>
        </Col>
      ) : (
        <Col>
        <Space>
          Pallet
          <Input
            placeholder="Pallet"
            value={pallet}
            onChange={(e) => setPallet(e.target.value)}
          />
        </Space>
      </Col>
      )}
    </Row>
  );
};

export default UnmatchedLabelsControls;
