import ProcessingResult from "./ProcessingResult";

export class ProcessingRequestBase {
  timestamp: string = "";

  public static from(json: any) {
    return Object.assign(
      new ProcessingRequestBase(),
      json
    ) as ProcessingRequestBase;
  }
}

export class ProcessingRequestShow extends ProcessingRequestBase {
  id: string = "";
  idx: number = 0;
  status: string = "";
  error_message: string = "";
  input_files: Array<string> = [];
  processing_result: ProcessingResult | null = null;

  public static override from(json: any) {
    return Object.assign(
      new ProcessingRequestShow(),
      json
    ) as ProcessingRequestShow;
  }
}
