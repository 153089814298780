import { Input, Col, Row, Space } from "antd";
import { useCallback } from "react";

interface PositionHistoryControlsProps {
  position: string;
  onApply: (position: string) => void;
}

const PositionHistoryControls = (props: PositionHistoryControlsProps) => {
  const onApply = useCallback(
    (value: string) => {
      props.onApply(value);
    },
    [props]
  );

  return (
    <Row gutter={20}>
      <Col>
        <Space>
          Position
          <Input
            placeholder="Position"
            value={props.position}
            onChange={(e) => onApply(e.target.value)}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default PositionHistoryControls;
