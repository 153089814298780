import { Card, Col, Divider, Row, Statistic } from "antd";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LinearScale,
  Tooltip,
  Title,
} from "chart.js";
import { useCallback, useEffect, useState } from "react";
import { PositionHistoryAPIHelper } from "../../../api-helpers/PositionHistoryAPIHelper";
import { CorridorOccupationSchema } from "../../../models/PositionHistory";
import { StatsAPIHelper } from "../../../api-helpers/StatsAPIHelper";
import { OccupationHistoryChart } from "./Dashboard/OccupationHistoryChart";
import { faker } from "@faker-js/faker";
Chart.register([
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
]);

function Dashboard() {
  const [totalPositions, setTotalPositions] = useState<number>(1);
  const [fullPositions, setFullPositions] = useState<number>(1);
  const [emptyPositions, setEmptyPositions] = useState<number>(1);

  const [distribution, setOccupationDistribution] = useState<
    Array<CorridorOccupationSchema>
  >([]);

  const updateContent = useCallback(() => {
    PositionHistoryAPIHelper.getWarehouseStateAsync().then((states) => {
      if (states == null) {
        return;
      }
      StatsAPIHelper.getOccupationDistributionAsync().then((distribution) => {
        if (distribution == null) {
          return;
        }
        const total = states.length;
        const full = states.filter(
          (state) => state.latest_record.pallet !== ""
        ).length;
        const empty = total - full;
        setTotalPositions(total);
        setFullPositions(full);
        setEmptyPositions(empty);
        setOccupationDistribution(distribution);
      });
    });
  }, []);

  // ComponentDidMount equivalent
  useEffect(() => {
    updateContent();
    const interval = setInterval(() => {
      updateContent();
    }, 10000);
    // ComponentWillUnmount equivalent
    return () => clearInterval(interval);
  }, [updateContent]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Statistic title="Total Positions" value={totalPositions} />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Statistic title="Full Positions" value={fullPositions} />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ height: "100%" }}>
            <Statistic title="Empty Positions" value={emptyPositions} />
          </Card>
        </Col>
      </Row>
      <Divider>Warehouse Status</Divider>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card style={{ height: "100%", alignContent: "center", justifyContent: "center" }}>
            <Doughnut
              width="100"
              height="300"
              options={{
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: "Total Occupation",
                    font: {
                      size: 16,
                    },
                  },
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      font: {
                        size: 12,
                      },
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return context.label + ": " + context.parsed;
                      },
                    },
                  },
                },
              }}
              data={{
                labels: ["Full Positions", "Empty Positions"],
                datasets: [
                  {
                    data: [fullPositions, emptyPositions],
                    backgroundColor: ["#36A2EB", "#5ce67c"],
                  },
                ],
              }}
            />
          </Card>
        </Col>
        <Col span={16}>
          <Card style={{ height: "100%" }}>
            <Bar
              height="100%"
              // width="250"
              options={{
                responsive: true,
                plugins: {
                  title: {
                    display: true,
                    text: "Occupation Distribution",
                    font: {
                      size: 16,
                    },
                  },
                  legend: {
                    position: "top" as const,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        return " " + context.parsed.y.toFixed(1) + "%";
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    stacked: true,
                  },
                  y: {
                    stacked: true,
                    max: 100,
                    ticks: {
                      // Include a dollar sign in the ticks
                      callback: function (value: any) {
                        return value + "%";
                      },
                    },
                  },
                },
              }}
              data={{
                // labels: distribution.map((item) => item.corridor.toUpperCase()),
                labels: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
                datasets: [
                  {
                    label: "Occupied Positions",
                    backgroundColor: "rgba(92, 230, 124, 0.6)",
                    borderColor: "#5ce67c",
                    borderWidth: 1,
                    //   data: distribution.map(
                    //     (item) =>
                    //       (item.occupied_positions /
                    //         (item.occupied_positions + item.empty_positions)) *
                    //       100
                    //   ),
                    // },
                    // Fake some corridors from
                    data: [
                      "A",
                      "B",
                      "C",
                      "D",
                      "E",
                      "F",
                      "G",
                      "H",
                      "I",
                      "J",
                    ].map(() => faker.datatype.number({ min: 50, max: 100 })),
                  },
                ],
              }}
            />
          </Card>
        </Col>
      </Row>
      <Divider>History</Divider>
      <Row>
        <Col span={24}>
          <Card style={{ height: "100%" }}>
            <OccupationHistoryChart />
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default Dashboard;
