export default class Label {
  idx: number = 0;
  type: string = "";
  detected_label: string = "";
  manual_label: string = "";
  crop_path: string = "";
  valid: boolean = false;
  reading: string = "";

  public static from(json: any) {
    return Object.assign(new Label(), json) as Label;
  }
}
