import { Button, Card, Form, Input, Layout, notification, Row } from "antd";
import { LoginAPIHelper } from "../../api-helpers/LoginAPIHelper";

interface LoginPageProps {
  onSuccess?: (token: string) => void;
}

function LoginPage(props: LoginPageProps) {
  const onFinish = (values: any) => {
    LoginAPIHelper.login(values["username"], values["password"])
      .then((token) => {
        if (token == null) {
          notification.error({
            message: "Error de credenciales.",
            description: "Intente nuevamente.",
          });
          return;
        }
        if (props.onSuccess) {
          props.onSuccess(token);
        }
      })
      .catch((e) => {
        notification.error({
          message: "Error de credenciales.",
          description: "Intente nuevamente.",
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout
      style={{
        height: "100vh",
        width: "100vw",
        margin: "auto",
        display: "table",
        textAlign: "center",
      }}
    >
      <Layout.Content
        style={{
          height: "100%",
          width: "100%",
          margin: "auto",
          display: "table",
          textAlign: "center",
          backgroundImage: "linear-gradient(#0066ff, #270a9e)",
        }}
      >
        <Row
          justify="space-around"
          align="middle"
          style={{
            height: "100%",
          }}
        >
          <Card title="AlgoLabs AI Central" style={{ borderRadius: "10px" }}>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Usuario"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un nombre de usuario.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese su contraseña.",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Acceder
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default LoginPage;
