import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;
const API_RESOURCE = 'login/';

export class LoginAPIHelper {
  public static async login(username: string, password: string): Promise<string | null> {
    try {
      const response: AxiosResponse = await axios.post(
        API_BASE_URL + API_RESOURCE + 'access-token',
        new URLSearchParams({
          username: username,
          password: password,
        }).toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      const tokenData = response.data;
      sessionStorage.setItem('token', tokenData.access_token);
      return tokenData.access_token;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async validateAccessToken(): Promise<boolean> {
    try {
      const response: AxiosResponse = await axios.post(
        API_BASE_URL + API_RESOURCE + 'test-token',
        {},
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return response.status === 200;
    } catch (error) {
      console.error(error);
      sessionStorage.removeItem('token');
      return false;
    }
  }

  public static getAccessToken(): string | null {
    return sessionStorage.getItem('token');
  }

  public static getAuthHeader(): { Authorization: string } {
    const token = LoginAPIHelper.getAccessToken();
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return { Authorization: '' };
    }
  }
}