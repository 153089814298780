import {
  Button,
  Card,
  Form,
  Modal,
  Space,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import { FolderAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { RcFile } from "antd/lib/upload";
import {HashedFileBase} from "../../../../models/HashedFile";
import UploadingFiles from "./subcomponents/UploadingFiles";
import {ProcessingRequestShow} from "../../../../models/ProcessingRequest";

interface ProcessingRequestFormProps {
  onUploadComplete: any;
  retryRequest: ProcessingRequestShow | null;
}

function ProcessingRequestForm(props: ProcessingRequestFormProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [inputZip, setInputZip] = useState<RcFile | null>(null);
  const [inputFolder, setInputFolder] = useState<RcFile[] | null>(null);
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (props.retryRequest?.idx !== undefined) {
      console.log("someProp has changed:", props.retryRequest);
    }
  }, [props.retryRequest]);

  const onUploadComplete: any = () => {
    setReadyToUpload(false);
    props.onUploadComplete();
  };

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    for (let i = 0; i < newFileList.length; i++) {
      newFileList[i].status = "done";
    }
    setFileList(newFileList);
  };

  const onFolderSelected = (file: RcFile, filelist: RcFile[]) => {
    setInputFolder(filelist);
    setInputZip(null);
    setReadyToSubmit(true);
  };

  const onFinish = () => {
    if (inputZip === null && inputFolder === null) {
      return;
    }
    let inputFiles;
    if (inputFolder !== null) {
      inputFiles = inputFolder.map((f) => {
        const inputFile = new HashedFileBase();
        inputFile.filename = f.name;
        inputFile.size = f.size / 1e6;
        return inputFile;
      });
    } else {
      if (inputZip !== null) {
        const inputFile = new HashedFileBase();
        inputFile.filename = inputZip.name;
        inputFile.size = inputZip.size / 1e6;
        inputFiles = [inputFile];
      }
    }
    if (inputFiles === undefined) {
      return;
    }
    if (props.retryRequest === null) {
      return;
    }
    setReadyToUpload(true);
    return;
  };
  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card title={<Space size={20}>Retry Processing Request</Space>}>
          <Form onFinish={onFinish}>
              <Form.Item name="image_directory" label="Image Folder">
                <Upload
                  action={""}
                  beforeUpload={onFolderSelected}
                  maxCount={10}
                  fileList={fileList}
                  onChange={onChange}
                  directory
                >
                  <Button icon={<FolderAddOutlined />}>Select</Button>
                </Upload>
              </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!readyToSubmit}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
      {readyToUpload && inputFolder && props.retryRequest !== null ? (
        <Modal
          title="Uploading files"
          visible={readyToUpload}
          closable={false}
          footer={null}
        >
          <UploadingFiles
            files={inputFolder}
            request={props.retryRequest}
            onUploadComplete={onUploadComplete}
          />
        </Modal>
      ) : (
        <></>
      )}
      {readyToUpload && inputZip && props.retryRequest ? (
        <Modal
          title="Uploading file"
          visible={readyToUpload}
          closable={false}
          footer={null}
        >
          <UploadingFiles
            files={[inputZip]}
            request={props.retryRequest}
            onUploadComplete={onUploadComplete}
          />
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}

export default ProcessingRequestForm;
