import ImageProcessingResult from "./ImageProcessingResult";

export class PositionHistoryRecord {
  idx: number = 0;
  timestamp: string = "";
  pallet: string = "";
  source_image: ImageProcessingResult = new ImageProcessingResult();

  public static from(json: any) {
    return Object.assign(
      new PositionHistoryRecord(),
      json
    ) as PositionHistoryRecord;
  }
}

export class PositionHistory {
  idx: number = 0;
  position: string = "";
  records: PositionHistoryRecord[] = [];

  public static from(json: any) {
    return Object.assign(
      new PositionHistory(),
      json
    ) as PositionHistory;
  }
}

export class WarehouseStateEntrySchema {
  position: string = "";
  latest_record: PositionHistoryRecord = new PositionHistoryRecord();

  public static from(json: any) {
    return Object.assign(
      new WarehouseStateEntrySchema(),
      json
    ) as WarehouseStateEntrySchema;
  }
}

export class CorridorOccupationSchema {
  corridor: string = "";
  occupied_positions: number = 0;
  empty_positions: number = 0;

  public static from(json: any) {
    return Object.assign(
      new CorridorOccupationSchema(),
      json
    ) as CorridorOccupationSchema;
  }
}
