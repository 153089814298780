import {
  Button,
  Card,
  DatePicker,
  Form,
  Modal,
  Space,
  Spin,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import locale from "antd/es/date-picker/locale/es_ES";
import dayjs from "dayjs";
import { RequestsAPIHelper } from "../../../../api-helpers/RequestsAPIHelper";
import UploadingFiles from "./subcomponents/UploadingFiles";
import { ProcessingRequestShow } from "../../../../models/ProcessingRequest";
import ReactDOM from "react-dom";

interface ProcessingRequestFormProps {
  onUploadComplete: (success: boolean) => void;
}

function NewProcessingRequestForm(props: ProcessingRequestFormProps) {
  const [selectedFiles, setSelectedFiles] = useState<RcFile[]>([]);
  const [date, setDate] = useState<string>(new Date().toISOString());
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false);
  const [request, setRequest] = useState<ProcessingRequestShow | null>(null);
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false);
  const [readingFiles, setReadingFiles] = useState<boolean>(false);
  const [errorFiles, setErrorFiles] = useState<RcFile[]>([]);
  const [uploadReady, setUploadReady] = useState<boolean>(false);

  const confirmSubmit = () => {
    // Confirm with API
    RequestsAPIHelper.confirmRequestAsync(request!.idx).then((result) => {
      if (result === null) {
        notification.error({
          message: "Error confirming request",
          description: `There was an error confirming the request.`,
        });
        return;
      }
    });
    props.onUploadComplete(true);
  };

  const cancelSubmit = () => {
    // Cancel with API
    RequestsAPIHelper.deleteRequestAsync(request!.idx).then((result) => {
      if (result === null) {
        notification.error({
          message: "Error deleting request",
          description: `There was an error deleting the request.`,
        });
        return;
      }
    });
    props.onUploadComplete(false);
  };

  const onUploadComplete = (errorFiles: RcFile[]) => {
    setReadyToUpload(false);
    if (errorFiles.length > 0) {
      notification.error({
        message: "Error uploading files",
        description: "Some files failed to upload.",
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setErrorFiles(errorFiles);
        setUploadReady(true);
      });
      return;
    }
    notification.success({
      message: "Files uploaded",
      description: `All files were uploaded successfully.`,
    });
    confirmSubmit();
  };

  const onFilesSelectedChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    for (let i = 0; i < newFileList.length; i++) {
      newFileList[i].status = "done";
    }
    setReadingFiles(false);
    setSelectedFiles(newFileList.map((f) => f.originFileObj!));
    setReadyToSubmit(true);
  };

  const onFileSelected = useCallback(
    (file: RcFile, filelist: RcFile[]) => {
      if (selectedFiles.find((f) => f.name === file.name)) {
        notification.error({
          message: "File with the same name already selected",
          description: `File ${file.name} already selected.`,
        });
        setReadingFiles(false);
        return Upload.LIST_IGNORE;
      }
    },
    [selectedFiles]
  );

  const onDateChanged = (date: any, date_string: string) => {
    if (date !== null) {
      setDate(moment(date_string).utc().format());
    }
  };


  const onFinish = () => {
    if (selectedFiles === null || date === "") {
      return;
    }
    RequestsAPIHelper.createRequestAsync(date)
      .then((result) => {
        if (result === null) {
        }
        setReadyToUpload(true);
        setRequest(result);
      })
      .catch(() => {
        setReadyToUpload(false);
      });
    return;
  };
  if (uploadReady && errorFiles.length > 0) {
    return (
      <Modal
        title="Some files failed to upload"
        open={uploadReady}
        closable={false}
        onOk={confirmSubmit}
        onCancel={cancelSubmit}
      >
        <div>Some files failed to upload.</div>
        <div>
          Successfull uploads: {selectedFiles.length - errorFiles.length}
        </div>
        <div>Failed uploads: {errorFiles.length}</div>
        Do you want to proceed?
      </Modal>
    );
  }
  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Card title={<Space size={20}>New Processing Request</Space>}>
          <Form onFinish={onFinish}>
              <Form.Item name="image_files" label="Image Files">
                <Upload
                  customRequest={() => {
                    return true;
                  }}
                  beforeUpload={onFileSelected}
                  onChange={onFilesSelectedChange}
                  multiple={true}
                  showUploadList={false}
                  accept="image/*"
                >
                  <Space>
                    <Button
                      icon={<FileAddOutlined />}
                      type="primary"
                      shape="round"
                    >
                      Add
                    </Button>
                    {selectedFiles.length !== 0 ? (
                      <div>
                        {selectedFiles.length} file
                        {selectedFiles.length > 1 ? "s" : ""} selected
                      </div>
                    ) : (
                      <></>
                    )}
                  </Space>
                </Upload>
              </Form.Item>
            
            <Form.Item name="date" label="Record Date">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime={true}
                placeholder={"Seleccione fecha"}
                showNow={false}
                locale={locale}
                allowClear={false}
                onChange={onDateChanged}
                defaultValue={dayjs(date)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!readyToSubmit}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Space>
      {readyToUpload && selectedFiles && request ? (
        <Modal
          title="Uploading files"
          visible={readyToUpload}
          closable={false}
          footer={null}
        >
          <UploadingFiles
            files={selectedFiles}
            request={request}
            onUploadComplete={onUploadComplete}
          />
        </Modal>
      ) : (
        <></>
      )}
      {readingFiles ? (
        <Modal
          title={
            <div>
              <Spin></Spin>
              Reading Files
            </div>
          }
          visible={readingFiles}
          closable={false}
          footer={null}
        ></Modal>
      ) : (
        <></>
      )}
    </>
  );
}

export default NewProcessingRequestForm;
