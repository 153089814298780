import { useCallback, useEffect, useMemo, useState } from "react";
import { PositionHistory } from "../../../../models/PositionHistory";
import { PositionHistoryAPIHelper } from "../../../../api-helpers/PositionHistoryAPIHelper";
import { Card } from "antd";
import PositionHistoryControls from "./subcomponents/PositionHistoryControls";
import PositionHistoryTable from "./subcomponents/PositionHistoryTable";
import { useSearchParams } from "react-router-dom";

const PositionHistoryView = () => {
  const [positionHistory, setPositionHistory] = useState<PositionHistory>(
    new PositionHistory()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const positionFilter = useMemo(() => {
    return searchParams.get("position") || "";
  }, [searchParams]);

  const updateContent = useCallback(() => {
    setLoading(true);
    PositionHistoryAPIHelper.getSingleAsync(positionFilter).then((res) => {
      if (res === null) {
        setLoading(false);
        return;
      }
      setPositionHistory(res);
      setLoading(false);
    });
  }, [positionFilter]);

  const onApplyFilters = useCallback(
    (position: string) => {
      setSearchParams({ position: position });
    },
    [setSearchParams]
  );

  useEffect(() => {
    updateContent();
  }, [updateContent]);

  return (
    <>
      <Card title="Position History">
        <PositionHistoryControls
          onApply={onApplyFilters}
          position={positionFilter}
        />
      </Card>
      <Card>
        <PositionHistoryTable
          positionHistory={positionHistory}
          loading={loading}
        />
      </Card>
    </>
  );
};

export default PositionHistoryView;
