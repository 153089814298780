import { Menu, MenuProps, Modal } from "antd";
import {
  DashboardOutlined,
  PullRequestOutlined,
  PoweroffOutlined,
  TableOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React from "react";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items = [
  getItem("Dashboard", 0, <DashboardOutlined />),
  getItem("Sweeps", 1, <PullRequestOutlined />),
  getItem("Warehouse", 2, <TableOutlined />),
  getItem("Position History", 3, <HistoryOutlined />),
  getItem("Logout", -1, <PoweroffOutlined />),
];

const paths = ["/", "/requests", "/warehouse", "/positionhistory", ""];

function LeftMenu() {
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);

  const onClick: MenuProps["onClick"] = (e) => {
    if (e.key === "-1") {
      setShowLogoutModal(true);
      return;
    }
    navigate(paths[Number(e.key)]);
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    window.location.reload();
    navigate("/");
  };

  return (
    <>
      <Menu
        theme="dark"
        onClick={onClick}
        defaultSelectedKeys={["0"]}
        defaultOpenKeys={["0"]}
        mode="inline"
        items={items}
      ></Menu>
      <Modal
        title="Logout"
        visible={showLogoutModal}
        onOk={logout}
        onCancel={() => setShowLogoutModal(false)}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
}

export default LeftMenu;
