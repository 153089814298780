import axios, { AxiosResponse } from "axios";
import { LoginAPIHelper } from "./LoginAPIHelper";
import { ProcessingRequestShow } from "../models/ProcessingRequest";
import { RcFile } from "antd/lib/upload";
import Label from "../models/Label";

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;
const API_RESOURCE = "requests/";

export class RequestsAPIHelper {
  public static async getAllRequestsAsync(
    query_string: string = ""
  ): Promise<ProcessingRequestShow[] | null> {
    try {
      const response: AxiosResponse<ProcessingRequestShow[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + "?" + query_string,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return response.data.map((dto) => ProcessingRequestShow.from(dto));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getSingleRequestAsync(
    id: string
  ): Promise<ProcessingRequestShow | null> {
    try {
      const response: AxiosResponse<ProcessingRequestShow> = await axios.get(
        API_BASE_URL + API_RESOURCE + id + "/",
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return ProcessingRequestShow.from(response.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async createRequestAsync(
    timestamp: string
  ): Promise<ProcessingRequestShow | null> {
    try {
      const requestData = {
        timestamp,
      };

      const response: AxiosResponse<ProcessingRequestShow> = await axios.post(
        API_BASE_URL + API_RESOURCE,
        requestData,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return ProcessingRequestShow.from(response.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async uploadFileAsync(
    request_idx: number,
    file: RcFile,
    hash: string,
    size: number,
    onUploadProgress: ((progressEvent: any) => void) | undefined
  ): Promise<ProcessingRequestShow | null> {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("hash", hash);
      formData.append("size", size.toString());

      const response: AxiosResponse<ProcessingRequestShow> = await axios.post(
        API_BASE_URL + API_RESOURCE + request_idx.toString() + "/file",
        formData,
        {
          onUploadProgress: onUploadProgress,
          headers: {
            ...LoginAPIHelper.getAuthHeader(),
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return ProcessingRequestShow.from(response.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getFileStatusAsync(
    hash: string
  ): Promise<Boolean | null> {
    try {
      const response: AxiosResponse<Boolean> = await axios.get(
        API_BASE_URL + "files/exists/" + hash,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async deleteRequestAsync(requestIdx: number): Promise<boolean> {
    try {
      const response: AxiosResponse<null> = await axios.delete(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/`,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      if (response.status === 204) {
        return true;
      } else {
        console.error("Failed to delete request.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  public static async deleteFileAsync(
    requestIdx: number,
    localPath: string
  ): Promise<boolean> {
    try {
      const response: AxiosResponse<null> = await axios.delete(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/file?local_path=${encodeURIComponent(
          localPath
        )}`,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      if (response.status === 204) {
        return true;
      } else {
        console.error("Failed to delete file.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  public static async confirmRequestAsync(
    requestIdx: number
  ): Promise<boolean> {
    try {
      const response: AxiosResponse<null> = await axios.put(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/confirm`,
        {},
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      if (response.status === 204) {
        return true;
      } else {
        console.error("Failed to confirm request.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  public static async approveRequestAsync(
    requestIdx: number
  ): Promise<boolean> {
    try {
      const response: AxiosResponse<null> = await axios.put(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/approve`,
        {},
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to approve request.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }
  public static async rejectRequestAsync(requestIdx: number): Promise<boolean> {
    try {
      const response: AxiosResponse<null> = await axios.put(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/reject`,
        {},
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      if (response.status === 200) {
        return true;
      } else {
        console.error("Failed to approve request.");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  }

  public static async getReviewCSVAsync(
    requestIdx: number
  ): Promise<Blob | null> {
    try {
      const response: AxiosResponse<Blob> = await axios.get(
        `${API_BASE_URL}${API_RESOURCE}${requestIdx}/csv?base_url=${window.location.origin}/`,
        {
          headers: LoginAPIHelper.getAuthHeader(),
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async editLabelAsync(
    result_idx: number,
    label_idx: number,
    label: string
  ): Promise<Label | null> {
    try {
      const response: AxiosResponse<Label> = await axios.put(
        API_BASE_URL +
          API_RESOURCE +
          result_idx +
          "/labels/" +
          label_idx +
          "?manual_label=" +
          label,
        {},
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return Label.from(response.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
