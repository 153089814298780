import { useCallback, useMemo, useState } from "react";
import { Image, Table, Button, Divider } from "antd";
import { Breakpoint } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Label from "../../../../../models/Label";
import EditableLabel from "./EditableLabel";
import { Link } from "react-router-dom";
import { ProcessingRequestShow } from "../../../../../models/ProcessingRequest";
import UnmatchedLabelsControls from "./UnmatchedLabelsControls";
const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;

interface UnmatchedLabelTableItem {
  label: Label;
  result_path: string;
  info_url: string;
}

interface UnmatchedLabelsTableTabProps {
  request: ProcessingRequestShow;
  type: "position" | "pallet";
}

function UnmatchedLabelsTableTab(props: UnmatchedLabelsTableTabProps) {
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [palletFilter, setPalletFilter] = useState<string>("");
  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
    ) => {
      setPositionFilter(position);
      setPalletFilter(pallet);
    },
    []
  );
  const unmatchedLabels: UnmatchedLabelTableItem[] = useMemo(() => {
    const mappedData: UnmatchedLabelTableItem[] = [];
    if (props.request.processing_result === null) {
      return mappedData;
    }
    for (const result of props.request.processing_result
      .image_processing_results) {
      const unmatchedLabels =
        props.type === "position"
          ? result.unmatched_positions
          : result.unmatched_pallets;
      for (let i = 0; i < unmatchedLabels.length; i += 1) {
        const label = unmatchedLabels[i];
        const resultPath = API_BASE_URL.slice(0, -1) + result.result_path;
        const matchedLabelTableItem: UnmatchedLabelTableItem = {
          label: label,
          result_path: resultPath,
          info_url:
            "?tab=4&imageId=" +
            props.request.processing_result.image_processing_results
              .indexOf(result)
              .toString(),
        };
        mappedData.push(matchedLabelTableItem);
      }
    }
    let filtered = mappedData;
    if (positionFilter !== "") {
      filtered = filtered.filter((entry) =>
        (entry.label.manual_label === ""
          ? entry.label.detected_label
          : entry.label.manual_label
        )
          .toLocaleUpperCase()
          .includes(positionFilter.toLocaleUpperCase())
      );
    }
    if (palletFilter !== "") {
      filtered = filtered.filter((entry) => {
        if (entry.label === null){
          return false
        }
        return (entry.label.manual_label === ""
          ? entry.label.detected_label
          : entry.label.manual_label)
              .toLocaleUpperCase()
              .includes(palletFilter.toLocaleUpperCase());
      });
    }
    return filtered;
  }, [
    props,
    positionFilter,
    palletFilter,
  ]);

  const columns = [
    {
      title: props.type === "position" ? "Position Label" : "Pallet Label",
      key: "label",
      render: (item: UnmatchedLabelTableItem) => {
        if (props.request.processing_result === null) {
          return "";
        }
        return (
          <EditableLabel
            label={item.label}
            result={props.request.processing_result}
            requestIdx={props.request.idx}
          />
        );
      },
    },
    {
      title: "Image",
      key: "image",
      render: (item: UnmatchedLabelTableItem) => {
        return (
          <Image
            src={item.result_path}
            alt={"Unable to load image."}
            height={"50px"}
          />
        );
      },
    },
    {
      title: "Details",
      responsive: ["lg"] as Breakpoint[],
      render: (item: UnmatchedLabelTableItem) => (
        <Link to={"/requests/" + props.request.idx + item.info_url}>
          <Button
            type="primary"
            shape="round"
            icon={<InfoCircleOutlined />}
            onClick={() => {
              console.log(props);
            }}
          >
            Info
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <UnmatchedLabelsControls type={props.type} onApply={onApplyFilters}/>
      <Divider/>
      <Table columns={columns} dataSource={unmatchedLabels} rowKey="id" />
    </>
  );
}

export default UnmatchedLabelsTableTab;
