import axios, { AxiosResponse } from "axios";
import {
  PositionHistory,
  WarehouseStateEntrySchema,
} from "../models/PositionHistory";
import { LoginAPIHelper } from "./LoginAPIHelper";

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;
const API_RESOURCE = "history/";

export class PositionHistoryAPIHelper {
  public static async getAllAsync(
    query_string: string = ""
  ): Promise<PositionHistory[] | null> {
    try {
      const response: AxiosResponse<PositionHistory[]> = await axios.get(
        API_BASE_URL + API_RESOURCE + "?" + query_string,
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return response.data.map((dto) => PositionHistory.from(dto));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getWarehouseStateAsync(): Promise<
    WarehouseStateEntrySchema[] | null
  > {
    try {
      const response: AxiosResponse<WarehouseStateEntrySchema[]> =
        await axios.get(API_BASE_URL + API_RESOURCE + "warehouse/state/", {
          headers: LoginAPIHelper.getAuthHeader(),
        });

      return response.data.map((dto) => WarehouseStateEntrySchema.from(dto));
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getWarehouseStateCSVAsync(): Promise<Blob | null> {
    try {
      const response: AxiosResponse<Blob> = await axios.get(
        API_BASE_URL + API_RESOURCE + "warehouse/state?as_file=true",
        {
          headers: LoginAPIHelper.getAuthHeader(),
          responseType: "blob",
        }
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public static async getSingleAsync(
    position: string
  ): Promise<PositionHistory | null> {
    try {
      const response: AxiosResponse<PositionHistory> = await axios.get(
        API_BASE_URL + API_RESOURCE + position + "/",
        {
          headers: LoginAPIHelper.getAuthHeader(),
        }
      );

      return PositionHistory.from(response.data);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
