import { Card, Col, Progress, Row } from "antd";
import { RcFile } from "antd/es/upload";
import React, { useCallback, useEffect, useState } from "react"; // importing FunctionComponent
import { ProcessingRequestShow } from "../../../../../models/ProcessingRequest";
import { RequestsAPIHelper } from "../../../../../api-helpers/RequestsAPIHelper";
import CryptoJS from "crypto-js";
import ReactDOM from "react-dom";

function fileMD5(file: RcFile): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      var binary = e.target.result;
      var md5 = CryptoJS.MD5(binary).toString();
      resolve(md5);
    };
    fileReader.onerror = (e) => {
      reject(e);
    };
    fileReader.readAsBinaryString(file);
  });
}

interface UploadingFilesProps {
  files: RcFile[];
  request: ProcessingRequestShow;
  onUploadComplete: (errorFiles: RcFile[]) => void;
}

function UploadingFiles(props: UploadingFilesProps) {
  const [fileHashes, setFileHashes] = useState<string[]>([]);
  const [currentHashingFile, setCurrentHashingFile] = useState<RcFile | null>(
    null
  );
  const [currentUploadingFile, setCurrentUploadingFile] =
    useState<RcFile | null>(null);
  const [pendingHashes, setPendingFiles] = useState<RcFile[]>(props.files);
  const [pendingUploads, setPendingUploads] = useState<RcFile[]>(props.files);
  const [errorFiles, setErrorFiles] = useState<RcFile[]>([]);

  const uploadFileIfNeeded = useCallback(
    async (file: RcFile, hash: string, request: ProcessingRequestShow) => {
      // const status = await RequestsAPIHelper.getFileStatusAsync(hash);
      return RequestsAPIHelper.uploadFileAsync(
        request.idx,
        file,
        hash,
        file.size,
        (progressEvent) => {
          console.log(progressEvent);
        }
      ).then((response) => {
        if (response) {
          return true;
        } else {
          return false;
        }
      });
    },
    []
  );

  // Compute the hashes of all files
  useEffect(() => {
    if (pendingHashes.length === 0) {
      return;
    }
    if (currentHashingFile) {
      return;
    }
    setCurrentHashingFile(pendingHashes[0]);
    fileMD5(pendingHashes[0]).then((hash) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setFileHashes([...fileHashes, hash]);
        setPendingFiles(pendingHashes.slice(1));
        setCurrentHashingFile(null);
      });
    });
  }, [pendingHashes, currentHashingFile, fileHashes]);

  // Upload the files
  useEffect(() => {
    if (pendingHashes.length !== 0) {
      return;
    }
    if (pendingUploads.length > 0) {
      if (currentUploadingFile) {
        return;
      }
      setCurrentUploadingFile(pendingUploads[0]);
      uploadFileIfNeeded(pendingUploads[0], fileHashes[0], props.request).then(
        (success) => {
          ReactDOM.unstable_batchedUpdates(() => {
            if (!success) {
              setErrorFiles([...errorFiles, pendingUploads[0]]);
            }
            setPendingUploads(pendingUploads.slice(1));
            setFileHashes(fileHashes.slice(1));
            setCurrentUploadingFile(null);
          });
        }
      );
    } else {
      props.onUploadComplete(errorFiles);
    }
  }, [
    pendingHashes,
    pendingUploads,
    fileHashes,
    currentUploadingFile,
    errorFiles,
    props,
    uploadFileIfNeeded,
  ]);

  const hashProgress = Math.round(
    ((props.files.length - pendingHashes.length) / props.files.length) * 100
  );
  const uploadProgress = Math.round(
    ((props.files.length - pendingUploads.length) / props.files.length) * 100
  );

  const progress = Math.round((hashProgress + uploadProgress) / 2);
  const action = pendingHashes.length !== 0 ? "Packing " : "Uploading ";
  const currentFile =
    pendingHashes.length !== 0
      ? pendingHashes[0]?.name
      : pendingUploads[0]?.name;

  return (
    <Card title={action + currentFile}>
      <Row align="middle" justify="space-around">
        <Col span={18}>
          <Progress percent={progress} />
        </Col>
      </Row>
    </Card>
  );
}

export default UploadingFiles;
