export class HashedFileBase {
  hash: string = "";
  path: string = "";
  filename: string = "";
  size: number = 0;

  public static from(json: any) {
    return Object.assign(new HashedFileBase(), json) as HashedFileBase;
  }
}

export class HashedFileShow extends HashedFileBase {
  _id: string = "";
  idx: number = 0;
  status: string = "";
  error_message: string = "";

  public static from(json: any) {
    return Object.assign(new HashedFileShow(), json) as HashedFileShow;
  }
}
