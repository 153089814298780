import { HistoryOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { WarehouseStateEntrySchema } from "../../../../../models/PositionHistory";
import { Button, Image, Table, Tag } from "antd";

const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;

interface WarehouseStateTableProps {
  state: WarehouseStateEntrySchema[];
  loading: boolean;
}

const WarehouseStateTable = (props: WarehouseStateTableProps) => {
  const columns = [
    {
      title: "",
      key: "image",
      render: (item: WarehouseStateEntrySchema) => {
        const path =
          API_BASE_URL.slice(0, -1) +
          item.latest_record.source_image.result_path;
        return (
          <Image src={path} alt={"Unable to load image."} height={"50px"} />
        );
      },
    },
    {
      title: "Position",
      key: "position",
      render: (item: WarehouseStateEntrySchema) => {
        return <Tag color={"#389e0d"}>{item.position.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Pallet",
      key: "item",
      render: (item: WarehouseStateEntrySchema) => {
        return (
          <Tag color={"#389e0d"}>
            {item.latest_record.pallet === ""
              ? "EMPTY"
              : item.latest_record.pallet.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "Corridor",
      key: "corridor",
      render: (item: WarehouseStateEntrySchema) =>
        item.position[0].toUpperCase(),
      sorter: (a: WarehouseStateEntrySchema, b: WarehouseStateEntrySchema) =>
        a.position[0].toUpperCase().localeCompare(b.position[0].toUpperCase()),
    },
    {
      title: "Column",
      key: "column",
      render: (item: WarehouseStateEntrySchema) => item.position.slice(2, 4),
      sorter: (a: WarehouseStateEntrySchema, b: WarehouseStateEntrySchema) =>
        a.position.slice(2, 4).localeCompare(b.position.slice(2, 4)),
    },
    {
      title: "Level",
      key: "level",
      render: (item: WarehouseStateEntrySchema) => item.position.slice(5, 6),
      sorter: (a: WarehouseStateEntrySchema, b: WarehouseStateEntrySchema) =>
        a.position.slice(5, 6).localeCompare(b.position.slice(5, 6)),
    },
    {
      title: "Last Update",
      key: "quantity",
      render: (item: WarehouseStateEntrySchema) => {
        return item.latest_record.timestamp;
      },
    },
    {
      title: "",
      key: "quantity",
      render: (item: WarehouseStateEntrySchema) => (
        <Link to={"/positionhistory?position=" + item.position}>
          <Button
            type="primary"
            shape="round"
            icon={<HistoryOutlined />}
            onClick={() => {
              console.log(props);
            }}
          >
            History
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.state}
      loading={props.loading}
      rowKey="position"
    />
  );
};

export default WarehouseStateTable;
