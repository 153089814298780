import Label from "./Label";
import LabelMatch from "./LabelMatch";

export default class ImageProcessingResult {
    idx: number = 0;
    timestamp: string = "";
    original_path: string = "";
    result_path: string = "";
    matched_labels: Array<LabelMatch> = [];
    unmatched_pallets: Array<Label> = [];
    unmatched_positions: Array<Label> = [];
    
    public static from(json: any) {
      return Object.assign(new ImageProcessingResult(), json) as ImageProcessingResult;
    }
  }
  