import { Input, Col, Row, Space, Select } from "antd";
import { useState, useCallback, useEffect } from "react";

interface MatchedLabelsControlsProps {
  onApply: (
    position: string,
    pallet: string,
    corridor: string,
    column: string,
    level: string,
    validity: string
  ) => void;
}

const MatchedLabelsControls = (props: MatchedLabelsControlsProps) => {
  const [position, setPosition] = useState<string>("");
  const [pallet, setPallet] = useState<string>("");
  const [corridor, setCorridor] = useState<string>("");
  const [column, setColumn] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [validity, setValidity] = useState<string>("");

  const onApply = useCallback(() => {
    props.onApply(position, pallet, corridor, column, level, validity);
  }, [props, position, pallet, corridor, column, level, validity]);

  useEffect(() => {
    onApply();
  }, [onApply]);

  return (
    <Row gutter={20}>
      <Col>
        <Space>
          Position
          <Input
            placeholder="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Pallet
          <Input
            placeholder="Pallet"
            value={pallet}
            onChange={(e) => setPallet(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Corridor
          <Input
            placeholder="Corridor"
            value={corridor}
            onChange={(e) => setCorridor(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Column
          <Input
            placeholder="Column"
            value={column}
            onChange={(e) => setColumn(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Level
          <Input
            placeholder="Level"
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          />
        </Space>
      </Col>
      <Col>
        <Space>
          Validity
          <Select
            defaultValue="All"
            style={{ width: 120 }}
            onChange={setValidity}
            options={[
              {
                value: "",
                label: "All",
              },
              {
                value: "valid",
                label: "Valid",
              },
              {
                value: "invalid",
                label: "Invalid",
              },
            ]}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default MatchedLabelsControls;
