import { Button, Table, Tag } from "antd";
import { Breakpoint } from "antd";
import { ProcessingRequestShow } from "../../../../../models/ProcessingRequest";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

interface RequestsHistoryTableProps {
  onRetry: any;
  requests: ProcessingRequestShow[];
}

function RequestsHistoryTable(props: RequestsHistoryTableProps) {
  const columns = [
    {
      title: "ID",
      key: "idx",
      responsive: ["lg"] as Breakpoint[],
      render: (item: ProcessingRequestShow) => item.idx,
    },
    {
      title: "Timestamp",
      key: "timestamp",
      render: (item: ProcessingRequestShow) => {
        let date = new Date(item.timestamp);
        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        date = new Date(date.getTime() - userTimezoneOffset);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      },
    },
    {
      title: "Files Uploaded",
      key: "ucount",
      responsive: ["lg"] as Breakpoint[],
      render: (item: ProcessingRequestShow) => item.input_files.length,
    },
    {
      title: "Status",
      key: "status",
      render: (item: ProcessingRequestShow) => {
        let color = "";
        switch (item.status) {
          case "uploading":
            color = "#13c2c2";
            break;
          case "pending":
            color = "#1677ff";
            break;
          case "processing":
            color = "#faad14";
            break;
          case "processed":
            color = "#52c41a";
            break;
          case "error":
            color = "#f5222d";
            break;
          case "rejected":
            color = "#fa541c";
            break;
          case "approved":
            color = "#237804";
            break;
          default:
            break;
        }
        return <Tag color={color}>{item.status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Image Results",
      key: "image_results",
      render: (item: ProcessingRequestShow) => {
        if (item.processing_result == null) {
          return "";
        }
        return item.processing_result.image_processing_results.length;
      },
    },
    {
      title: "Matched Labels",
      key: "matched_labels",
      render: (item: ProcessingRequestShow) => {
        if (item.processing_result == null) {
          return "";
        }
        return item.processing_result.image_processing_results.reduce(
          (totalCount, result) => totalCount + result.matched_labels.length,
          0
        );
      },
    },
    {
      title: "Unmatched Positions",
      key: "unmatched_positions",
      render: (item: ProcessingRequestShow) => {
        if (item.processing_result == null) {
          return "";
        }
        return item.processing_result.image_processing_results.reduce(
          (totalCount, result) =>
            totalCount + result.unmatched_positions.length,
          0
        );
      },
    },
    {
      title: "Unmatched Pallets",
      key: "unmatched_pallets",
      render: (item: ProcessingRequestShow) => {
        if (item.processing_result == null) {
          return "";
        }
        return item.processing_result.image_processing_results.reduce(
          (totalCount, result) => totalCount + result.unmatched_pallets.length,
          0
        );
      },
    },
    {
      title: "Details",
      responsive: ["lg"] as Breakpoint[],
      render: (item: ProcessingRequestShow) => (
        <>
          {item.processing_result != null &&
          ["processed", "approved", "rejected"].includes(item.status) ? (
            <Link to={"/requests/" + item.idx + "?tab=1"}>
              <Button
                type="primary"
                shape="round"
                icon={<EyeOutlined />}
              >
                Review
              </Button>
            </Link>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return <Table columns={columns} dataSource={props.requests} rowKey="id" />;
}

export default RequestsHistoryTable;
