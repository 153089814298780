import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

faker.seed(42);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          return " " + context.parsed.y.toFixed(1) + "%";
        },
      },
    },
  },
  scales: {
    y: {
      min: 0,
      max: 100,
      ticks: {
        // Include a dollar sign in the ticks
        callback: function (value: any) {
          return value + "%";
        },
      },
    },
  },
};

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Warehouse Occupation",
      data: labels.map(() => faker.datatype.number({ min: 50, max: 100 })),
      borderColor: "rgb(54, 163, 235)",
      backgroundColor: "rgba(54, 163, 235, 0.5)",
    },
  ],
};

export const OccupationHistoryChart = () => {
  return <Line options={options} data={data} height="60" />;
};
