import { useCallback, useMemo, useState } from "react";
import { Image, Table, Button, Divider } from "antd";
import { Breakpoint } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import Label from "../../../../../models/Label";
import EditableLabel from "./EditableLabel";
import { Link } from "react-router-dom";
import { ProcessingRequestShow } from "../../../../../models/ProcessingRequest";
import MatchedLabelsControls from "./MatchedLabelsControls";
const API_BASE_URL = window._env_.REACT_APP_DRONE_INVENTORY_API_URL;

interface MatchedLabelTableItem {
  pallet_label: Label | null;
  position_label: Label;
  result_path: string;
  info_url: string;
}

interface MatchedLabelsTableTabProps {
  request: ProcessingRequestShow;
}

function MatchedLabelsTableTab(props: MatchedLabelsTableTabProps) {
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [palletFilter, setPalletFilter] = useState<string>("");
  const [corridorFilter, setCorridorFilter] = useState<string>("");
  const [columnFilter, setColumnFilter] = useState<string>("");
  const [levelFilter, setLevelFilter] = useState<string>("");
  const [validityFilter, setValidityFilter] = useState<string>("");

  const onApplyFilters = useCallback(
    (
      position: string,
      pallet: string,
      corridor: string,
      column: string,
      level: string,
      validity: string
    ) => {
      setPositionFilter(position);
      setPalletFilter(pallet);
      setCorridorFilter(corridor);
      setColumnFilter(column);
      setLevelFilter(level);
      setValidityFilter(validity);
    },
    []
  );

  const matchedLabelTableItems: MatchedLabelTableItem[] = useMemo(() => {
    const mappedData: MatchedLabelTableItem[] = [];
    if (props.request.processing_result === null) {
      return mappedData;
    }
    for (const result of props.request.processing_result
      .image_processing_results) {
      for (let i = 0; i < result.matched_labels.length; i += 2) {
        const matchedLabel = result.matched_labels[i];
        const palletLabel = matchedLabel.pallet_label;
        const positionLabel = matchedLabel.position_label;
        const resultPath = API_BASE_URL.slice(0, -1) + result.result_path;
        const matchedLabelTableItem: MatchedLabelTableItem = {
          pallet_label: palletLabel,
          position_label: positionLabel,
          result_path: resultPath,
          info_url:
            "?tab=4&imageId=" +
            props.request.processing_result.image_processing_results
              .indexOf(result)
              .toString(),
        };
        mappedData.push(matchedLabelTableItem);
      }
    }
    let filtered = mappedData;
    if (positionFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label.reading
          .toLocaleUpperCase()
          .includes(positionFilter.toLocaleUpperCase())
      );
    }
    if (palletFilter !== "") {
      filtered = filtered.filter((entry) => {
        if (entry.pallet_label === null) {
          return false;
        }
        return entry.pallet_label.reading
          .toLocaleUpperCase()
          .includes(palletFilter.toLocaleUpperCase());
      });
    }
    if (corridorFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label.reading[0]
          .toUpperCase()
          .includes(corridorFilter.toLocaleUpperCase())
      );
    }
    if (columnFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label.reading
          .toLocaleUpperCase()
          .slice(2, 4)
          .includes(columnFilter.toLocaleUpperCase())
      );
    }
    if (levelFilter !== "") {
      filtered = filtered.filter((entry) =>
        entry.position_label.reading
          .toLocaleUpperCase()
          .slice(4, 6)
          .includes(levelFilter.toLocaleUpperCase())
      );
    }
    if (validityFilter !== "") {
      filtered = filtered.filter((entry) => {
        let paletValid =
          (entry.pallet_label !== null && entry.pallet_label.valid) ||
          entry.pallet_label === null;
        if (validityFilter === "valid") {
          return entry.position_label.valid && paletValid;
        }
        return !entry.position_label.valid || !paletValid;
      });
    }
    return filtered;
  }, [
    props,
    positionFilter,
    palletFilter,
    corridorFilter,
    columnFilter,
    levelFilter,
    validityFilter,
  ]);

  const columns = [
    {
      title: "Position Label",
      key: "position_label",
      render: (item: MatchedLabelTableItem) => {
        if (props.request.processing_result === null) {
          return "";
        }
        return (
          <EditableLabel
            label={item.position_label}
            result={props.request.processing_result}
            requestIdx={props.request.idx}
          />
        );
      },
    },
    {
      title: "Corridor",
      key: "corridor",
      render: (item: MatchedLabelTableItem) => {
        try {
          return item.position_label.reading[0].toUpperCase();
        } catch (e) {
          return "";
        }
      },
    },
    {
      title: "Column",
      key: "column",
      render: (item: MatchedLabelTableItem) => {
        try {
          return item.position_label.reading.slice(2, 4);
        } catch (e) {
          return "";
        }
      },
    },
    {
      title: "Level",
      key: "level",
      render: (item: MatchedLabelTableItem) => {
        try {
          return item.position_label.reading.slice(5, 6);
        } catch (e) {
          return "";
        }
      },
    },
    {
      title: "Pallet Label",
      key: "pallet_label",
      render: (item: MatchedLabelTableItem) => {
        if (
          props.request.processing_result === null ||
          item.pallet_label == null
        ) {
          return "";
        }
        return (
          <EditableLabel
            label={item.pallet_label}
            result={props.request.processing_result}
            requestIdx={props.request.idx}
          />
        );
      },
    },
    {
      title: "Image",
      key: "image",
      render: (item: MatchedLabelTableItem) => {
        return (
          <Image
            src={item.result_path}
            alt={"Unable to load image."}
            height={"50px"}
          />
        );
      },
    },
    {
      title: "Details",
      responsive: ["lg"] as Breakpoint[],
      render: (item: MatchedLabelTableItem) => (
        <Link to={"/requests/" + props.request.idx + item.info_url}>
          <Button
            type="primary"
            shape="round"
            icon={<InfoCircleOutlined />}
            onClick={() => {
              console.log(props);
            }}
          >
            Info
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Divider orientation="left"> Filters </Divider>
      <MatchedLabelsControls onApply={onApplyFilters} />
      <Divider orientation="left">
        {" "}
        {matchedLabelTableItems.length + " "}Results{" "}
      </Divider>
      <Table
        columns={columns}
        dataSource={matchedLabelTableItems}
        rowKey="id"
      />
    </>
  );
}

export default MatchedLabelsTableTab;
